.search-box {
    width: 90%;
    height: 40px;
    gap: 10px; /* Adjust the gap between input and button */
}

.search-input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 17px;
    width: 90%;
    outline: none; /* Remove the default focus outline */
}

.search-input:focus {
    border-color: #ccc; /* Optional: Customize border color when focused */
    box-shadow: none; /* Optional: Remove any box-shadow if present */
}
