match-page-main {
    position: relative;
}

.match-page {
    background: #121315;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.match-page-header {
    width: 90%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.match-page-table-header {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    align-content: center;
}

.match-page-settings {
    background-color: white;
    /*background-image: url("./resources/settings_icon.png");*/
    /*background-color: transparent;*/
    /*border-color: transparent;*/
    width: 30px;
    height: 30px;
}

.match-page-title {
    margin-top: 15px;
    height: 40px;
    margin-bottom: 20px;
}

.match-page-list-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.match-page-list {

}

.match-page-list::-webkit-scrollbar {
    display: none;
}

.match-page-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.match-page-match-container {
    background-color: #1B1D29;
    width: 90%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
}

.match-page-match-table {
    margin-right: 15px;
    margin-top: 15px;
    text-align: right;
}

.match-page-match-name {
    margin-left: 15px;
    margin-top: 0px;
    text-align: left;
}

.match-page-match-age {
    margin-left: 15px;
    margin-top: 5px;
    text-align: left;
}

.match-page-match-interests {
    margin-left: 15px;
    margin-top: 5px;
    text-align: left;
}

.match-page-match-chart {
    display: flex;
    align-items: center;
    justify-content: center;
}

.match-page-match-score {
    margin-right: 15px;
    margin-top: 0px;
    text-align: right;
}

.match-page-buttons-container {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.match-page-left-main-button {
    background-image: url("../../resources/left_main_button.png");
    background-color: transparent;
    border-color: transparent;
    width: 55px;
    height: 55px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


.match-page-right-main-button {
    background-image: url("../../resources/right_main_button.png");
    background-color: transparent;
    border-color: transparent;
    width: 55px;
    height: 55px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.modal-match-page {
    margin-top: 20px;
    height: 50px;
    width: 80%;
}
