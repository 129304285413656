.survey-page {
    background: #121315;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.survey-back-button {
    height: 30px;
    margin-top: 20px;
    background-color: transparent;
    border: none;
    margin-left: 20px;
    width: 100px;
}

.survey-title-logo {
    background-image: url('./resources/title-logo.png');
    width: 80%;
    height: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.survey-content {
    width: 100%;
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}

.survey-content-page {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.survey-question {
    width: 90%;
}

.survey-answer {
    width: 80%;
    height: 60px;
    background-color: #1B1D29;
    border: 1px solid #6E6D6D;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    text-align: center;
    align-content: center;
    margin-top: 20px;
}

.survey-answer.selected {
    background: linear-gradient(90deg, #E20562, #FF8024);
    border: 1px solid white;
}
