.interests-form-container {
    background: #121315;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
}

.interests-form-inputs-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.interests-title {
    text-align: center;
    width: 100vw;
    height: 30px;
    margin-top: 10px;
}

.interests-content {
    flex: 1;
    overflow-y: auto;
}

.interests-scrollview-content {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}



.interests-gradient-bottom {
    position: absolute;
    bottom: 0;
    height: 200px;
    width: 100%;
    background: linear-gradient(0deg, rgba(18,19,21,1) 0%, rgba(18,19,21,1) 50%, rgba(18,19,21,0) 100%);
    z-index: 10;
}

.interests-collection-container {
    z-index: 5;
    display: flex;
    flex-wrap: wrap; /* Enable wrapping of elements */
    justify-content: flex-start; /* Align items to the start of the row */
    gap: 10px; /* Adjust the gap between ellipses as needed */
    padding-top: 40px;
    padding-bottom: 200px; /* Bottom padding */
    overflow-y: auto; /* Enable vertical scrolling */
    width: 80%;
    box-sizing: border-box; /* Ensure padding is included in the width and height */

}

.interests-ellipse{
    height: 10px;
    border-radius: 30px;
    background-color: #121315; /* Change color as needed */
    border: 2px solid #a2a2a2; /* Change border color as needed */
    padding: 15px 20px; /* Adjust padding as needed */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap; /* Ensure text doesn't wrap */
}

.interests-ellipse.selected {
    background: linear-gradient(90deg, #E20562, #FF8024);
    border: 2px solid #FFFFFF;
    color: #FFFFFF;
}

.interests-selected-elements-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    width: 90%;
    margin-bottom: 10px;
    margin-top: 10px;
}

.interests-selected-ellipse {
    background-color: #1B1D29;
    position: relative;
    height: 15px;
    border: 2px solid #FFFFFF;
    padding: 15px 10px;
    border-radius: 30px;
    display: inline-flex;
    align-items: center;
    color: white;
    white-space: nowrap;
}

.interests-remove-icon {
    margin-left: 10px;
    cursor: pointer;
    color: white;
    font-weight: bold;
}

.interests-form-button {
    background-color: #1B1D29;
    border: 1px solid #696969;
    font-family: Rubik, sans-serif;
    font-weight: bold;
    font-size: 20px;
    position: absolute;
    bottom: 50px;
    width: 300px;
    height: 50px;
    padding: 10px 20px;
    color: rgba(255, 255, 255, 0.7);
    border-radius: 25px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    z-index: 10;
}

.interests-form-button.isActive {
    color: white;
}


