.shared-custom-date-picker {
    width: 80vw;
    color: white;
    border: 1px solid #696969;
    background-color: #1B1D29;
    border-radius: 22px;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-weight: bold;
    padding: 5px 20px 5px 20px;
    height: 50px;
    box-sizing: border-box;
}

.shared-custom-date-picker:focus {
    border: 1px solid #ffffff;
}
