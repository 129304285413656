.profile-creation-page {
    background: #121315;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
}

.profile-creation-back-button {
    height: 30px;
    margin-top: 20px;
    background-color: transparent;
    border: none;
    margin-left: 20px;
    width: 100px;
}

.profile-creation-button.zindex {
    z-index: 100;
}

.profile-creation-content {
    width: 100%;
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}
