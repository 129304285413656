
.dropdown-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    position: relative;
    width: 100%;
}

.dropdown-input-custom-input {
    color: #696969;
    border: 1px solid #696969;
    background-color: #1B1D29;
    outline: none;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-weight: bold;
    border-radius: 20px;
    width: 100%;
    padding: 13px 20px;
    position: relative;
    text-align: left;
    box-sizing: border-box;
}

.dropdown-input-custom-input.active {
    border-color: white;
    color: white;
}

.dropdown-input-custom-input.selected {
    color: white;
}


.dropdown-container {
    position: absolute;
    top: 100%;
    width: 330px;
    background-color: #1B1D29;
    border-radius: 20px;
    border: 1px solid #696969;
    z-index: 1000;
    margin-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
}

.dropdown-item {
    padding: 15px 20px;
    color: white;
    text-align: left;
    font-family: 'Rubik', sans-serif;
    font-weight: bold;
    font-size: 16px;
}

.dropdown-item:hover {
    background-color: #343650; /* Lighter background on hover */
}

.dropdown-divider {
    height: 1px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #696969;
}

.dropdown-input-custom-input.selected {
    color: white;
}

.dropdown-icon {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    transition: transform 0.3s ease;
    background-image: url('./resources/dropdown-icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 12px;
    right: 20px;
}

.dropdown-icon.active {
    transform: rotate(180deg); /* Rotate the icon when the dropdown is active */
}
