.splash-screen-page {
    background: #121315;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.splash-screen-title-logo {
    background-image: url('./resources/title-logo.png');
    width: 80%;
    height: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 20px;
}

.splash-screen-logo {
    background-image: url('./resources/splash-logo.png');
    width: 100%;
    height: 200px;
    margin-left: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.splash-screen-title {
    width: 80%;
    margin-top: 20px;
}

.splash-screen-description {
    margin-top: 20px;
    width: 80%;
}

.splash-screen-button {
    width: 60%;
    height: 50px;
    margin-top: 30px;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 25px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}
