.main-button {
    background: linear-gradient(90deg, #E20562, #FF8024);
    color: white;
    font-family: "Rubik", sans-serif;
    border-radius: 25px;
    border: 0;
    font-size: 20px;
    font-weight: bold;
}

.secondary-button {
    width: 100%;
    padding-right: 20px;
    height: 50px;
    color: rgba(0, 0, 0, 60%);
    background: transparent;
    border: 0px;
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
}

.h18-b-gray {
    color: rgb(255, 255, 255, 0.8);
    font-family: "Rubik", sans-serif;
    font-size: 18px;
}

.h18-b {
    color: rgb(255, 255, 255, 0.7);
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-weight: bold;
}

.h16-bw {
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: rgba(255, 255, 255, 100%);
}

.h16-bw70 {
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: rgba(255, 255, 255, 70%);
}

.h20-bw {
    font-family: "Rubik", sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: rgba(255, 255, 255, 100%);
}

.h20-bw70 {
    font-family: "Rubik", sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: rgba(255, 255, 255, 70%);
}

.h20-w {
    font-family: "Rubik", sans-serif;
    font-size: 20px;
    color: rgba(255, 255, 255, 100%);
}

.h30-bw {
    margin-top: 30px;
    font-family: "Rubik", sans-serif;
    font-size: 30px;
    font-weight: bold;
    color: rgba(255, 255, 255, 100%);
}

.h40-bw {
    margin-top: 30px;
    font-family: "Rubik", sans-serif;
    font-size: 40px;
    font-weight: bold;
    color: rgba(255, 255, 255, 100%);
}

.h3 {
    color: rgba(101, 101, 101, 0.56);
    font-family: "Rubik", sans-serif;
    font-size: 15px;
}

.header-left-button {
    color: black;
    font-family: "Rubik", sans-serif;
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    top: 20px;
    left: 20px;
}

.gradient-top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 90%);
}


.gradient-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 25%, rgba(0, 0, 0, 0) 80%);
}

.heart-container {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.heart {
    width: 50px;
    height: 50px;
    align-self: center;
    background-position: center;
    background-image: url("resources/logo.png");
    background-size: contain;
}
