.page-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}

.dot {
    height: 10px;
    width: 10px;
    margin: 10px;
    background-color: #ccc;
    border-radius: 50%;
    transition: background-color 0.3s, transform 0.3s;
}

.dot.active {
    transform: scale(1.5);
    background: linear-gradient(90deg, #E20562, #FF8024);
}
