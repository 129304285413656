.match-page-detail {
    background: #121315;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.match-page-detail-back-header {
    position: absolute;
    left: 20px;
}

.match-page-detail-header {
    padding-top: 20px;
    position: absolute;
    background-color:  #121315;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.match-page-detail-table-header {
    margin: 0 auto;
}

.match-page-detail-settings {
    background-color: white;
    /*background-image: url("./resources/settings_icon.png");*/
    /*background-color: transparent;*/
    /*border-color: transparent;*/
    width: 30px;
    height: 30px;
}

.match-page-detail-title {
    margin-top: 80px;
    height: 40px;
}

.match-page-detail-container {
    margin-top: 10px;
    background-color: #1B1D29;
    border: 1px solid #FFFFFF;
    width: 85%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
}

.match-page-detail-row {
  margin-left: 10px;
}

.match-page-detail-interests-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-left: 10px;
    margin-top: 20px;
}

.match-page-detail-interests-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}


.match-page-detail-interests-item-text {
    flex: 1;
}

.match-page-detail-chart {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
