
.user-form-container {
    background: #121315;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width: 100%;
}

.user-form-inputs-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 2;
    align-items: center;
    position: relative;
    top: 0;
    left: 0;
}

.user-form-title {
    text-align: center;
    width: 100vw;
    height: 30px;
    margin-top: 10px;
}

.user-form-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.user-form-field {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 400px;
}

.user-form-field p {
    margin-bottom: 10px; /* Adjust the margin as needed */
    align-self: flex-start; /* Align label to the left */
}

input, .input-buttons, .input-date, .input-multichoice {
    width: 100%;
}

/* Adjust the margin between elements using the spacer div */
.spacer {
    height: 30px; /* Adjust the height as needed */
}

.user-form-button {
    background-color: #1B1D29;
    border: 1px solid #696969;
    font-family: Rubik, sans-serif;
    font-weight: bold;
    font-size: 20px;
    width: 300px;
    height: 50px;
    padding: 10px 20px;
    color: rgba(255, 255, 255, 0.7);
    border-radius: 25px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    z-index: 0;
    margin-top: 50px;
}

.user-form-button.isActive {
    color: white;
}



