.onboarding-page {
    background: white;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.onboarding-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.onboarding-description {
    margin-top: 100%;
    width: 80%;
    height: 100px;
    transition: opacity 0.5s ease-in-out;
}

.onboarding-page-indicator-container {
    width: 100%;
    height: 30px;
}

.onboarding-main-button {
    background-image: url("../../resources/right_main_button.png");
    background-color: transparent;
    border-color: transparent;
    width: 55px;
    height: 55px;
    margin-top: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
