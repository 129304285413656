.home-page {
    background: white;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.home-page-background {
    background-image: url("./resources/background.png");
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.home-page-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-page-logo {
    background-image: url("./resources/logo_name.png");
    margin-left: -25%;
    margin-top: 85%;
    width: 250px;
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.home-page-button {
    width: 60%;
    height: 50px;
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 25px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.home-page-message-description {
    position: absolute;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 50px;
    font-size: 20px;
    font-weight: bold;
}
