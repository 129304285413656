 .empty-state {
     background: #121315;
     height: 100%;
     width: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: flex-start;
 }

 .empty-state-title {
     margin-top: 50px;
     width: 80%;
 }

 .empty-state-image {
     background-image: url("./resources/kiddies.png");
     background-size: cover;
     background-repeat: no-repeat;
     background-position: center;
     height: 300px;
     width: 80%;
     margin-top: 50px;
 }

 .empty-state-description {
     margin-top: 50px;
     width: 80%;
 }
