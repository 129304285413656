.image-transition-container {
    background-color: black;
    position: absolute;
    width: 100vw; /* Adjust to fit your container size */
    height: 100vh; /* Adjust based on your needs */
    overflow: hidden;
}

.image-transition-fade-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease-in-out;
}
