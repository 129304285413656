.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
}

.input-custom-input {
    color: white;
    border: 1px solid #696969;
    background-color: #1B1D29;
    outline: none;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-weight: bold;
    border-radius: 20px;
    flex: 1;
    padding: 13px 20px 13px 20px;
    box-sizing: border-box;
}

.input-custom-input:focus {
    border: 1px solid #FFFFFF;
    color: white;
}

.input-custom-input::placeholder {
    color: gray; /* Change the color of the placeholder text */
}
